import Vue  from 'vue'
import { host } from '@/../config/host.js'

const state = {}

const actions = {
    get_data({dispatch, commit}, monthYear){
        return new Promise((resolve, reject) => {
            Vue.axios.get(`${host}/report/`, {params: { date: monthYear }})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    get_year_data({dispatch, commit}, params){
        return new Promise((resolve, reject) => {
            Vue.axios.get(`${host}/report/year/`, {params: params})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    save_year_data({dispatch, commit}, data) {
        return new Promise((resolve, reject) => {
            Vue.axios.post(`${host}/report/year/`, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
