<template>
    <div>
        <div v-if="is_authed" id="app-container">
            <div class="header-block">
                <el-menu 
                    :default-active="$route.path" 
                    mode="horizontal" 
                    router>
                    <el-menu-item index="/">Версии</el-menu-item>
                    <el-menu-item index="/tasks">Задачи</el-menu-item>
                    <el-menu-item index="/report">Отчет</el-menu-item>
                    <el-menu-item index="/timesheet">Таймшит</el-menu-item>
                    <el-menu-item index="/year_report">Годовой отчет</el-menu-item>
                    <!-- <el-menu-item index="/bitbucket">Гит</el-menu-item> -->
                </el-menu>
            </div>
            <div class="main-block"><router-view /></div>
        </div>
        <div v-else><Login /></div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Login from './App/Login.vue'

export default {
    components: {
        Login
    },

    computed: {
        ...mapState({
            is_authed   : state => state.app.is_authed,
            user        : state => state.app.user,
        }),
    },

    methods: {
        
    },

    created: function() {
        this.$store.dispatch('app/check_auth')
    },

    
}
</script>

<style>
    html, body {
        font-family: sans-serif;
    }
    .header-block {
        height: 60px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .main-block {
        height: calc(100vh - 128px);
        overflow: auto;
        padding: 24px;
    }
</style>
